#SessionExpirationNotification {
	padding: 1rem;
	position: relative;
	box-sizing: border-box;
	background-color: #fef7e9;
	width: 100%;
	height: 3.75rem;

	.SessionExpirationNotificationContent {
		width: 1100px;
		margin: 0 auto;
		line-height: 1.75rem;
	}

	.NotificationIcon {
		font-size: 1.5rem;
		vertical-align: middle;

		&.NotificationCloseIcon {
			position: absolute;
			right: 1rem;
			top: 1rem;
			color: #313233;
			cursor: pointer;
		}
	}

	.NotificationText {
		margin: 0 0 0 0.5rem;
		font-size: 13px;
		line-height: 1rem;
		display: inline-block;
		color: #313233;
		vertical-align: middle;
	}

	.NotificationLogOutButton {
		margin: 0 0 0 3rem;
		padding: 0.25rem 0.5rem;
		font-size: 13px;
		line-height: 1rem;
		display: inline-block;
		box-sizing: border-box;
		text-align: center;
		border: 2px solid #313233;
		border-radius: 3px;
		background-color: #ffffff;
		color: #313233;
		text-decoration: none;
		cursor: pointer;
		outline: none;
		vertical-align: middle;
	}
}
