/**
 * @file Login.css
 *
 * Styles the University Login module in Homepage
 *
 * @author Mariasoprana Atteo <mariasoprana@studyportals.eu>
 * @author Niel Hekkens <niel@studyportals.eu>
 * @copyright © 2016-2018 StudyPortals B.V., all rights reserved.
 * @version 1.1.0
 */

@import './Templates/Endor/CSS/_SASSEndor.scss';

#Login {
	background-color: $GreyLL;
	padding: 2rem 0.5rem;

	a,
	a span {
		color: $BrandOrange;

		&:hover {
			color: $BrandOrangeD;
		}

		&.Forgot {
			@include FontSize('N');
			color: $BrandBlue;

			&:hover {
				color: $BrandBlueD;
			}
		}
	}

	.Button {
		@include FontSize('N');
		margin-bottom: 1em;
		min-width: 10em;
		color: white;
		&:hover {
			color: white;
		}
	}

	form[name='Login'] {
		display: inline-block;
		margin: auto;
		text-align: left;

		label,
		input,
		button {
			display: block;
			margin: 0.5em 0;
		}

		label {
			@include FontSize('N');
			color: $GreyD;
		}

		input {
			border: 1px solid $Grey;
			min-width: 19.5em;
			padding: 0.5em;
		}

		.ErrorMessage {
			color: $RedError;
			display: block;
			margin: 0.5em 0;
		}
	}
}

#block-login .SessionExpirationSignedOutNotification {
	width: 34rem;
	margin: 2rem auto 0;
}
