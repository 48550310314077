@import './Templates/Endor/CSS/_SASSEndor.scss';

.EditorBaseForm {
	fieldset {
		margin-bottom: 3rem;
		background-color: $White;
		position: relative;
		padding: 1.2rem 1.75rem 1.5rem;

		.FieldContainer {
			margin-top: 2.5rem;
			clear: both;
			display: inline-block;
			width: 100%;

			& > label:first-of-type,
			& > *:first-of-type label:first-of-type {
				margin-top: 0;
			}

			.CharacterCountFeedback {
				@include FontSize('SN');
				color: $BrandBlue;
				display: block;

				&.OverLimit {
					color: $Red;
				}
			}

			.Note {
				font-size: 0.7rem;
				color: $RedError;
				margin-left: 0.1rem;
			}
		}
	}

	legend {
		@include HeadingSize('H4');
		border-bottom: 1px solid $GreyDD;
		padding-bottom: 0.5rem;
		color: $GreyDD;
		float: left;
		width: 100%;
		margin-bottom: 0;

		&::after {
			clear: both;
			content: ' ';
			display: block;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	h1 {
		margin-bottom: 1rem;
	}

	a {
		color: $BrandBlue;

		&:hover {
			color: $BrandBlueD;
		}

		&:visited {
			color: $BrandBlueD;
		}
	}

	.WYSIWYGContainer {
		.WYSIWYG {
			height: inherit;
			min-height: 4rem;
			max-height: 350px;

			&[contenteditable='false'] {
				background-color: #eeeeee;

				&:hover {
					cursor: not-allowed;
				}
			}
		}
	}

	[disabled='disabled'] {
		&.Disabled {
			background-color: inherit;
			border: 0;
		}
	}

	input[type='text'],
	input[type='number'],
	input[type='url'],
	input[type='date'],
	select {
		@include FontSize('N');
		display: block;
		border: 0;
		border-bottom: 1px solid $Grey;
		min-width: 2.5rem;

		&:focus {
			border-color: $BrandBlue;

			& + label {
				color: $BrandBlue;
			}
		}
	}

	textarea {
		@include FontSize('N');
		display: block;
		border: 1px solid $Grey;
		border-bottom: 3px solid $Grey;
		min-width: 2.5rem;
		padding: 1%;
		min-height: 2rem;
		width: 100%;

		&:focus {
			border-bottom-color: $BrandBlue;

			& + label {
				color: $BrandBlue;
			}
		}
	}

	input[type='text'],
	input[type='url'] {
		width: 100%;
		padding: 0.5rem 0.5rem 0.5rem 0;
		min-height: 2rem;
	}

	input[type='number'] {
		max-width: 4rem;
	}

	input[type='checkbox'] {
		vertical-align: middle;

		&:checked + label {
			color: $GreyDD;
		}

		& + label {
			display: inline-block;
			margin-bottom: 0;
			vertical-align: middle;
		}
	}

	label {
		@include FontSize('SN');
		display: inline-block;
		margin: 0;
		color: $GreyD;

		&:first-letter {
			text-transform: uppercase;
		}
	}

	input[type='radio'] + label {
		color: $GreyDD;
	}

	abbr {
		border-bottom: 0 none;
		cursor: default;
		text-transform: uppercase;
	}

	.SideNote {
		@include FontSize('SN');
		color: $GreyD;
		display: block;
		font-style: italic;
	}

	button.Button {
		&[type='submit'] {
			background: $BrandBlue;
			border-color: $BrandBlueD;
			margin-top: 1rem;

			&:hover {
				background: $BrandBlueD;
			}
		}
	}

	.InlineInput {
		label {
			display: block;
			margin-bottom: 0.5rem;
		}

		input,
		select {
			display: inline-block;
			margin: 0 0.5rem;
			vertical-align: initial;
		}
	}

	#form_id {
		color: $Uneditable;
	}

	.Clear {
		clear: both;
	}
}

.Toggle {
	display: none;

	&:after,
	&:before,
	& *,
	& *:after,
	& *:before,
	& + .tgl-btn {
		box-sizing: border-box;

		&::selection {
			background: none;
		}
	}

	+ .ToggleButton {
		outline: 0;
		display: inline-block;
		width: 4em;
		height: 2em;
		position: relative;
		cursor: pointer;
		user-select: none;
		vertical-align: middle;

		&:after,
		&:before {
			position: relative;
			display: block;
			content: '';
			width: 50%;
			height: 100%;
		}

		&:after {
			left: 0;
		}

		&:before {
			display: none;
		}
	}

	&:checked + .ToggleButton:after {
		left: 50%;
	}

	& + .ToggleButton + .ToggleStatus:after {
		@include FontSize('SN');
		content: attr(data-off);
		color: $RedError;
		display: inline-block;
		margin-top: 0.5rem;
	}

	&:checked + .ToggleButton + .ToggleStatus:after {
		content: attr(data-on);
		color: $Green;
	}
}

.ToggleLight {
	+ .ToggleButton {
		background: $Grey;
		border-radius: 2em;
		padding: 2px;
		transition: all 0.2s;
		-webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2) inset;
		box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2) inset;

		&:after {
			border-radius: 50%;
			background: #ffffff;
			transition: all 0.2s;
			box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.1);
			-webkit-box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.1);
		}
	}

	&:checked + .ToggleButton {
		background: $Green;
	}
}
