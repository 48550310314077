/**
 * @file Homepage.scss
 *
 * Structural styling of the homepage template.
 *
 * @author Mariasoprana Atteo <mariasoprana@studyportals.com>
 * @author José Lammers <jose@studyportals.com>
 * @author Niel Hekkens <niel@studyportals.com>
 * @copyright © 2016-2018 StudyPortals B.V., all rights reserved.
 * @version 1.1.0
 */

// Importing the main Sass file.
@import './SASSEndor';

body {
	#Introduction {
		#block-welcome {
			margin-bottom: 1rem;
			margin-top: 4rem;

			.IntroductionTitle {
				@include HeadingSize('H3');
				color: $GreyDD;
				margin-bottom: 1rem;
			}

			p {
				@include FontSize('N');
			}
		}

		#block-login {
			margin-bottom: 8rem;
		}
	}

	a {
		text-decoration: none;
	}

	ul {
		list-style: none;
		margin: 1em 0;

		li {
			color: $Grey;
		}
	}

	.col1 {
		.colblock {
			text-align: center;

			#LoginContainer {
				position: relative;
			}
		}
	}

	h1,
	h2 {
		font-weight: bold;
	}

	#GeneralInformation {
		a {
			color: $BrandBlue;

			&:hover {
				color: $BrandBlueD;
			}
		}

		a.Button {
			margin-top: 2em;
			color: $White;
		}

		.phoneNumber {
			white-space: nowrap;
		}
	}

	@include Breakpoint(Medium, Large, ExtraLarge) {
		.col2 {
			.colblock {
				p {
					max-width: 95%;
				}
			}
		}
	}
}
