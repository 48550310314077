/**
 * @file Header.scss
 *
 * Styling for both client facing header and internal header.
 *
 * @author José Lammers <jose@studyportals.com>
 * @copyright © 2017 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 *
 */
@import './Templates/Endor/CSS/_SASSEndor.scss';
@import '~@studyportals/styles-abstracts/mixins/_spacing.scss';

/* Make sure to keep this variable synchronised with:
- $gapNav in Templates/Endor/CSS/Dashboard.scss
- $gapNav in Packages/Endor/Modules/Faq/CSS/Faq.scss
- $gapNav in Packages/Endor/Modules/Reporting/ClientReportingDashboard/CSS/ClientReportingDashboard.scss
- $gapNav in Packages/Endor/Modules/ACTDashboard/CSS/ACTDashboard.scss
- a value used in the way this.targetElement.width is calculated in Templates/Endor/JS/Components/ReportingDashboardLoader.js */
$navWidth: 4rem;
$navHoveredWidth: 15rem;

.HorizontalHeader {
	@include PaddingAreas(0);
	background-color: $BrandBlue;

	.Container {
		@include PaddingAreas(0);
		margin: 0 auto;
		width: 100%;
		max-width: 1220px;
		height: $navWidth;
		overflow: hidden;
	}

	.Logo {
		vertical-align: top;
		font-size: 0;

		img {
			@include MarginAreas(0);
			@include PaddingAreas(1);
			box-sizing: border-box;
			display: inline-block;
		}
	}

	.LogOutBtn {
		@include FontSize('N');
		@include MarginAreas(1);
		padding: 5px 10px;
		float: right;
		opacity: 0.6;
		color: $White;
		border: 1px solid $White;
		border-radius: 5px;
		text-decoration: none;
		display: block;
	}

	.LogOutBtn:hover {
		transition: opacity 0.3s ease-in;
		opacity: 1;
	}
}

.ClientHeaderModule {
	height: 58px;
}

.ClientActionsMenu {
	position: fixed;
	background-color: $White;
	z-index: 1;
	box-shadow: 0 2px 2px 0 rgba(63, 92, 110, 0.15);
	width: 100%;
	text-align: right;

	.LogOutBtn {
		@include FontSize('N');
		@include PaddingAreas(0.25, 0.5);
		margin: 0.75rem;
		color: $GreyDD;
		border-radius: 1.5rem;
		text-decoration: none;
		display: inline-block;
		width: 6.5rem;
		transition: width 0.2s ease-in-out;

		.LogOutBtnContent {
			width: 6rem;
			display: inline-block;
			text-align: left;
		}

		.LogOutIcon {
			font-size: 1.5rem;
			vertical-align: middle;
		}

		.LogOutText {
			@include MarginAreas(0, 0, 0, 0.25);
			vertical-align: middle;
			display: inline-block;
		}

		&:hover {
			background-color: $BlueLL;
			color: $BrandBlueD;
		}
	}
}

.VerticalHeader {
	@include PaddingAreas(0);
	position: fixed;
	background-color: $BrandBlue;
	z-index: 50;
	width: 4rem;
	height: 100vh;
	overflow: hidden;
	transition: width 0.2s ease-in-out;

	&:hover {
		width: $navHoveredWidth;
	}

	.Container {
		@include PaddingAreas(0);
		margin: 0 auto;
		width: $navHoveredWidth;
	}

	.Logo {
		display: block;
		vertical-align: top;
		background-color: $BrandOrange;
		font-size: 0;

		.LogoAcropolis {
			@include MarginAreas(0);
			padding: 8px 13px 10px 12px;
			box-sizing: content-box;
			vertical-align: middle;
		}

		.LogoText {
			@include MarginAreas(0);
			padding: 1rem 1rem 0.75rem 0;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: middle;
		}
	}

	.NavMain {
		display: block;
		vertical-align: top;

		ul {
			@include MarginAreas(0);
			@include PaddingAreas(0);

			li {
				@include MarginAreas(0);
				list-style: none;
				display: block;
				border-bottom: 1px solid $Grey;

				&:hover {
					background-color: #0d6489;
				}

				&.is-current {
					background-color: $BrandBlueD;
				}

				a {
					@include FontSize('N');
					display: block;
					color: $White;
					line-height: 3.5rem;
				}

				a:hover {
					text-decoration: none;
				}
			}
		}

		.NavIcon {
			font-size: 1.5rem;
			padding: 1.25rem;
			vertical-align: middle;
		}

		.NavText {
			@include FontSize('N');
			vertical-align: middle;
		}
	}

	&::after {
		content: ' ';
		display: block;
		height: 0;
		clear: both;
	}

	.IconSparklesWrapper {
		position: relative;
	}

	.IconSparkles {
		position: absolute;
		height: 26px;
		width: 26px;
		top: 0.75rem;
		left: 2.6rem;
		vertical-align: unset;

		#Star1 {
			fill: $Yellow;
			opacity: 0.4;
			transform-box: fill-box;
			transform-origin: 50% 50%;
			transform: scale(1, 1);
			animation: star1_Animation 3s cubic-bezier(0.42, 0, 1, 1) infinite;
		}

		@keyframes star1_Animation {
			0% {
				opacity: 0.4;
				transform: scale(1, 1);
			}

			50% {
				opacity: 1;
				transform: scale(1.4, 1.4);
			}

			100% {
				opacity: 0.4;
				transform: scale(1, 1);
			}
		}

		#Star2 {
			fill: $Yellow;
			opacity: 0.6;
			animation: star2_Animation 3s cubic-bezier(0.42, 0, 1, 1) infinite;
		}

		@keyframes star2_Animation {
			0% {
				opacity: 0.6;
			}

			33.33% {
				opacity: 0.2;
			}

			66.67% {
				opacity: 1;
			}

			100% {
				opacity: 0.6;
			}
		}

		#Star3 {
			fill: $Yellow;
			opacity: 1;
			animation: star3_Animation 3s cubic-bezier(0, 0, 0.58, 1) infinite;
		}

		@keyframes star3_Animation {
			0% {
				opacity: 1;
			}

			23.33% {
				opacity: 0.4;
			}

			50% {
				opacity: 0.8;
			}

			74.44% {
				opacity: 0.4;
			}

			100% {
				opacity: 1;
			}
		}
	}

	.HighlightedAsNew {
		@include PaddingAreas(0.25, 0.5);
		@include MarginAreas(0, 0, 0, 0.5);
		@include FontSize('S');
		vertical-align: middle;
		border-radius: 1rem;
		background-color: $YellowL;
		color: $GreyDD;
	}
}

.FullWidthContent.ClientContent {
	padding-left: $navWidth;
}

#HeaderModule {
	.HorizontalHeader .Logo img {
		height: 4rem;
	}

	.VerticalHeader .Logo {
		.LogoAcropolis {
			width: 2.5rem;
		}

		.LogoText {
			width: 10rem;
		}
	}
}
