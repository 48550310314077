/**
 * @file Common.css
 *
 * Structural styling of all templates, including neutral background colors.
 *
 * <p>
 * Do -not- put any module-only CSS in here or I will violate your face
 * with a shovel. (Rob).</p>
 *
 * @author Rob Janssen <rob@studyportals.eu>
 * @copyright © 2012 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 */

.Content {
	width: 1100px;
	margin: auto;
	position: relative;
}

/* Palette */

body {
	background-color: #f9f9f9;
	color: #555555;

	.Hidden {
		display: none !important;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
	color: #1179ad;
}

.SearchButton:hover {
	background: #ef5600;
}

.DetailsButton:hover {
	background: #e8e8e8;
}

body .fieldsetForm .SubmitForm {
	background: #50930e;
}

/* Header */

#Logo {
	height: 65px;
	position: relative;
	width: 380px;
	background: url(../Images/Home.png) no-repeat left center;
}

#LoginContainer {
	position: absolute;
	left: 380px;
	top: 0;
}

#Home {
	position: absolute;
	top: 0;
	left: 0;
	height: 50px;
	width: 386px;
	z-index: 1004;
}

#PortalSelect {
	position: absolute;
	top: 0;
	left: 386px;
	display: block;
	height: 65px;
	width: 244px;
	z-index: 1003;
	/* Firefox 16 draws a dotted box around a clicked link. This masks it somewhat. */
	color: #7b7b7b;
}

#Logo .Arrow {
	position: absolute;
	display: block;
	background: url(../Images/Arrow.png) no-repeat 0 0;
	height: 24px;
	right: 22px;
	top: 32px;
	width: 21px;
}

.Extended #Logo .Arrow {
	background-position: 0 -24px;
}

/* Portal overview pane */

#Portals {
	/*background-color: #7B7B7B;*/
	height: 65px;
	/* Smoothly animates transition to "Extended" class */
	transition: height 1s;
	-webkit-transition: height 1s;
	-moz-transition: height 1s;
}

#Portals.Extended {
	height: 438px;
}

#Portals .Module.PortalOverview.Shown {
	height: 331px;
	transition: height 1s;
}

#Portals a {
	text-decoration: none;
}

#Portals a:hover {
	text-decoration: underline;
}

#Portals .Module.PortalOverview {
	height: 0;
	overflow: hidden;
	transition: height 1s;
}

#Portals #PortalOverview {
	height: 328px;
	overflow: hidden;
	list-style-type: none;
}

#HeaderLinks {
	font-size: 0.95em;
	line-height: 14px;
	position: absolute;
	right: 0;
	text-align: right;
	top: 30px;
}

#HeaderLinks span {
	display: block;
}

/* Search pane */

#Search {
	border-bottom: 1px solid #cccccc;
	background-color: #4c4c4c;
}

#ContentContainer {
	background-color: #ffffff;
	border-color: #d2d2d2;
	box-shadow: 0 0 30px -15px #787878;
	-moz-box-shadow: 0 0 30px -15px #787878;
	-webkit-box-shadow: 0 0 30px -15px #787878;
}

/* Trail pane */

#Trail ul li:last-child {
	border: none;
}

#Trail ul li {
	border-right: 1px solid #999999;
	display: inline-block;
	margin-right: 10px;
	padding-right: 10px;
}

/* Hub Pane */

#Hub {
	background-color: #cccccc;
	padding-bottom: 32px;
}

/* Page element styles */

/* Grey gradient, portal-color text */

.DetailsButton {
	display: inline-block;
	margin-bottom: 7px;
	height: 21px;
	padding: 8px 20px 0;
	border-radius: 3px;
	min-width: 135px;
	text-align: center;
	border-radius: 5px;
	border-style: solid;
	border-width: 1px;
	text-decoration: none;
	font-size: 12px;
	font-weight: bold;
	box-shadow:
		0 0 5px 0 #aaaaaa,
		0 0 1px 0 #ffffff inset;
}

/* Has portal-color gradient, plain text. */

.SearchButton {
	display: inline-block;
	margin-bottom: 7px;
	height: 21px;
	padding: 8px 20px 0;
	border-radius: 3px;
	min-width: 135px;
	text-align: center;
	border-style: solid;
	border-width: 1px;
	text-decoration: none;
	font-size: 12px;
	font-weight: bold;
	box-shadow:
		0 0 5px 0 #aaaaaa,
		0 0 1px 0 #ffffff inset;
}

/**********************************************************/

.fieldsetForm {
	position: relative; /* needed as an positional anchor for the overtext */
}

.fieldsetForm fieldset {
	border: 0 none;
	margin: 0 0 20px 60px;
	width: 300px;
}

.fieldsetForm label {
	line-height: 20px;
	padding: 0 0 0 7px;
	width: 278px;
	float: left;
}

.fieldsetForm label.overTxtLabel {
	padding-top: 7px !important;
	font-weight: 500 !important;
	color: #a0a0a0 !important;
}

.fieldsetForm input[type='checkbox'] ~ label,
.fieldsetForm input[type='radio'] ~ label {
	line-height: 20px;
	padding: 0 0 0 10px;
	width: 260px;
	clear: right;
	cursor: pointer;
	margin-bottom: 3px;
}

.fieldsetForm input[type='checkbox'],
.fieldsetForm input[type='radio'] {
	float: left;
	cursor: pointer;
	margin-bottom: 3px;
}

.fieldsetForm input[type='text'],
.fieldsetForm input[type='password'],
.fieldsetForm select,
.fieldsetForm .SelectablesContainer,
.fieldsetForm textarea {
	padding: 7px;
	width: 190px;
	border: 1px solid #bbbbbb;
	color: #555555;
	box-shadow: 0 0 0 1px #ffffff inset;
	margin: 0 0 10px;
	background: #f5f5f5;
}

.fieldsetForm input[type='text'],
.fieldsetForm input[type='password'] {
	width: 275px;
}

.fieldsetForm .Score {
	width: 30px !important;
	float: left;
}

.fieldsetForm select {
	width: 290px;
}

.fieldsetForm .dateGroup select {
	width: 87px;
}

.fieldsetForm .dateGroup strong {
	font-weight: 900;
	content: '-';
}

.fieldsetForm .SelectablesContainer {
	width: 275px;
	height: 300px;
	overflow: hidden;
	margin-bottom: 10px;
}

.fieldsetForm .SelectablesContainer ul {
	margin: 0;
	list-style-type: none;
}

.fieldsetForm .SelectablesContainer ul.mainList {
	overflow: auto;
	height: 296px;
}

.fieldsetForm .SelectablesContainer ul.mainList li {
	height: auto;
	overflow: hidden;
	margin-bottom: 1px;
}

.fieldsetForm .SelectablesContainer ul.mainList li.Selected {
	background-color: #00a4db;
	color: #ffffff;
}

.fieldsetForm .SelectablesContainer ul.mainList li span {
	cursor: pointer;
	display: block;
	padding: 10px;
}

.fieldsetForm .SelectablesContainer ul.mainList li span:hover {
	background-color: #b2daff;
	color: #000000;
}

.fieldsetForm .SelectablesContainer ul.mainList li.Selected span:hover {
	background-color: #00a4db;
	color: #ffffff;
}

.fieldsetForm .SelectablesContainer ul.mainList li .groupName {
	border-bottom: 1px dotted #666666;
}

.fieldsetForm .SelectablesContainer ul.mainList li ul {
	margin-left: 10px;
}

.fieldsetForm textarea {
	width: 278px;
	padding: 5px;
}

.fieldsetForm .SubmitForm {
	padding: 0.5em;
	cursor: pointer;
	border: 2px solid #ffffff;
	box-shadow:
		2px 2px 8px rgba(0, 0, 0, 0.3),
		0 -5px 10px rgba(0, 0, 0, 0.2) inset;
	display: block;
	width: 290px;
	line-height: 1.6em;
	text-align: center;
	color: #ffffff;
	background: #cccccc;
	border-radius: 4px;
	text-decoration: none;
	margin: 10px 0;
	font-size: 1.2em;
	font-weight: bold;
	font-family: Arial, sans-serif;
	background: #cccccc;
}

.fieldsetForm .SubmitForm:hover {
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.fieldsetForm .staticData {
	width: 278px;
	line-height: 30px;
	float: left;
	padding: 0 6px;
	margin-bottom: 10px;
	color: #666666;
}

.errorText {
	color: #ff0000;
	font-style: italic;
}

.fieldsetForm .errorText {
	left: 390px;
	position: absolute;
	width: 250px;
}

/* Editor image alignment */

img[data-align='right'] {
	float: right;
	margin: 0.5em 0 0.5em 0.5em;
}

img[data-align='left'] {
	float: left;
	margin: 0.5em 0.5em 0.5em 0;
}

img[data-align='middle'] {
	margin: 0.5em auto;
	display: block;
}

button.Textlink,
input[type='button'].Textlink,
input[type='submit'].Textlink,
input[type='reset'].Textlink,
span.Clickable {
	background-color: transparent;
	font-size: inherit;
	font-family: inherit;
	border: 0;
	padding: 0;
	margin: 0;
	color: inherit;
	cursor: pointer;

	&::-moz-focus-inner {
		padding: 0;
		border: 0;
	}

	&:hover {
		color: inherit;
		text-decoration: underline;
	}
}
